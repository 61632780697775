import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

// Import des pages
import { LoginPage } from './pages/Auth';
import { DashboardPage } from './pages/Dashboard';
import { EmailTemplatesPage } from './pages/EmailTemplates';
import { DataSourcesPage } from './pages/DataSources';
import { CampaignsPage } from './pages/Campaigns';
import { SettingsPage } from './pages/Settings';
import { NotFoundPage } from './pages/NotFound';

// Composant pour les routes protégées
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

// Composant principal des routes
export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      
      <Route path="/" element={
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      } />
      
      <Route path="/email-templates" element={
        <ProtectedRoute>
          <EmailTemplatesPage />
        </ProtectedRoute>
      } />
      
      <Route path="/data-sources" element={
        <ProtectedRoute>
          <DataSourcesPage />
        </ProtectedRoute>
      } />
      
      <Route path="/campaigns" element={
        <ProtectedRoute>
          <CampaignsPage />
        </ProtectedRoute>
      } />
      
      <Route path="/settings" element={
        <ProtectedRoute>
          <SettingsPage />
        </ProtectedRoute>
      } />
      
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
