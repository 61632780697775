import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from '../../components/layout';
import { EmailTemplatesList, CreateEmailTemplate, EditEmailTemplate } from './index';

const EmailTemplatesPage: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<EmailTemplatesList />} />
        <Route path="/create" element={<CreateEmailTemplate />} />
        <Route path="/edit/:id" element={<EditEmailTemplate />} />
        <Route path="*" element={<Navigate to="/email-templates" replace />} />
      </Routes>
    </Layout>
  );
};

export default EmailTemplatesPage;
