import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  MenuItem, 
  Grid, 
  Typography,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  SelectChangeEvent
} from '@mui/material';
import { Button } from '../common';
import { useAppSelector } from '../../hooks';
import { Campaign } from '../../store/slices/campaignsSlice';
import { DataSource } from '../../services/dataSourcesService';
import { EmailTemplate } from '../../store/slices/emailTemplatesSlice';

interface CampaignFormProps {
  initialData?: Campaign;
  onSubmit: (data: any) => void;
  onCancel: () => void;
  loading: boolean;
  error: string | null;
}

const statusOptions = [
  { value: 'draft', label: 'Brouillon' },
  { value: 'active', label: 'Active' },
  { value: 'paused', label: 'En pause' },
  { value: 'completed', label: 'Terminée' },
];

const CampaignForm: React.FC<CampaignFormProps> = ({ 
  initialData, 
  onSubmit, 
  onCancel, 
  loading, 
  error 
}) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    sourceId: '',
    templateId: '',
    status: 'draft',
  });
  
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  
  const { items: dataSources } = useAppSelector(state => state.dataSources);
  const { items: emailTemplates } = useAppSelector(state => state.emailTemplates);

  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name,
        description: initialData.description,
        sourceId: initialData.sourceId,
        templateId: initialData.templateId,
        status: initialData.status,
      });
    }
  }, [initialData]);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Clear validation error when field is edited
    if (validationErrors[name]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    
    // Clear validation error when field is edited
    if (validationErrors[name]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validate = (): boolean => {
    const errors: Record<string, string> = {};
    
    if (!formData.name) {
      errors.name = 'Le nom est requis';
    }
    
    if (!formData.sourceId) {
      errors.sourceId = 'La source de données est requise';
    }
    
    if (!formData.templateId) {
      errors.templateId = 'Le modèle d\'email est requis';
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validate()) {
      return;
    }
    
    onSubmit(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Informations de la campagne</Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextField
            name="name"
            label="Nom"
            value={formData.name}
            onChange={handleTextFieldChange}
            fullWidth
            required
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          {initialData && (
            <FormControl fullWidth required error={!!validationErrors.status}>
              <InputLabel id="status-label">Statut</InputLabel>
              <Select
                labelId="status-label"
                name="status"
                value={formData.status}
                label="Statut"
                onChange={handleSelectChange}
              >
                {statusOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {validationErrors.status && (
                <FormHelperText>{validationErrors.status}</FormHelperText>
              )}
            </FormControl>
          )}
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Description"
            value={formData.description}
            onChange={handleTextFieldChange}
            fullWidth
            multiline
            rows={2}
          />
        </Grid>
        
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Configuration</Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required error={!!validationErrors.sourceId}>
            <InputLabel id="source-label">Source de données</InputLabel>
            <Select
              labelId="source-label"
              name="sourceId"
              value={formData.sourceId}
              label="Source de données"
              onChange={handleSelectChange}
            >
              {dataSources.map((source: DataSource) => (
                <MenuItem key={source.id} value={source.id}>
                  {source.name}
                </MenuItem>
              ))}
            </Select>
            {validationErrors.sourceId && (
              <FormHelperText>{validationErrors.sourceId}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required error={!!validationErrors.templateId}>
            <InputLabel id="template-label">Modèle d'email</InputLabel>
            <Select
              labelId="template-label"
              name="templateId"
              value={formData.templateId}
              label="Modèle d'email"
              onChange={handleSelectChange}
            >
              {emailTemplates.map((template: EmailTemplate) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
            {validationErrors.templateId && (
              <FormHelperText>{validationErrors.templateId}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button variant="secondary" onClick={onCancel} disabled={loading}>
          Annuler
        </Button>
        <Button type="submit" variant="primary" disabled={loading}>
          {loading ? 'Enregistrement...' : initialData ? 'Mettre à jour' : 'Créer'}
        </Button>
      </Box>
    </Box>
  );
};

export default CampaignForm;
