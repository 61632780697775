import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from './store';
import { AppRoutes } from './routes';
import { AuthProvider } from './contexts/AuthContext';
import { checkAuth } from './store/slices/authSlice';
import { useAppDispatch } from './hooks';

// Composant pour initialiser l'application
const AppInitializer: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Vérifier l'authentification au chargement de l'application
    dispatch(checkAuth());
  }, [dispatch]);

  return <AppRoutes />;
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider theme={createTheme({
          palette: { 
            mode: 'light',
            primary: {
              main: '#1976d2',
            },
            secondary: {
              main: '#dc004e',
            },
          },
          typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
          }
        })}>
          <CssBaseline />
          <Router>
            <AppInitializer />
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
