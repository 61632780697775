import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { 
  Email as EmailIcon, 
  CheckCircle as SuccessIcon, 
  Error as ErrorIcon,
  Schedule as ScheduleIcon
} from '@mui/icons-material';
import { Layout } from '../../components/layout';
import { Card } from '../../components/common';

// Composant pour les statistiques
interface StatCardProps {
  title: string;
  value: string | number;
  icon: React.ReactNode;
  color: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon, color }) => (
  <Card>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box 
        sx={{ 
          backgroundColor: `${color}20`, 
          borderRadius: '50%', 
          p: 2, 
          mr: 2,
          color
        }}
      >
        {icon}
      </Box>
      <Box>
        <Typography variant="h4" component="div">
          {value}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
      </Box>
    </Box>
  </Card>
);

const DashboardPage: React.FC = () => {
  // Dans une application réelle, ces données viendraient d'une API
  const stats = {
    totalEmails: 1256,
    successRate: 98.5,
    failureCount: 19,
    pendingCount: 45
  };

  return (
    <Layout>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 3 }}>
          Tableau de bord
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard 
              title="Emails envoyés" 
              value={stats.totalEmails} 
              icon={<EmailIcon />} 
              color="#3498db" 
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard 
              title="Taux de succès" 
              value={`${stats.successRate}%`} 
              icon={<SuccessIcon />} 
              color="#2ecc71" 
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard 
              title="Échecs" 
              value={stats.failureCount} 
              icon={<ErrorIcon />} 
              color="#e74c3c" 
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard 
              title="En attente" 
              value={stats.pendingCount} 
              icon={<ScheduleIcon />} 
              color="#f39c12" 
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <Card title="Activité récente">
            <Typography variant="body1">
              Aucune activité récente à afficher.
            </Typography>
          </Card>
        </Box>
      </Box>
    </Layout>
  );
};

export default DashboardPage;
