import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

// Configuration de base pour axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000, // 10 secondes
});

// Intercepteur pour ajouter le token d'authentification à chaque requête
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Intercepteur pour gérer les erreurs de réponse
api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // Gérer les erreurs d'authentification (401)
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    
    // Gérer les erreurs de serveur (500)
    if (error.response?.status === 500) {
      console.error('Erreur serveur:', error.response.data);
      // Vous pourriez ici envoyer l'erreur à un service de monitoring comme Sentry
    }
    
    // Gérer les erreurs de timeout
    if (error.code === 'ECONNABORTED') {
      console.error('La requête a expiré');
    }
    
    return Promise.reject(error);
  }
);

// Fonction utilitaire pour gérer les erreurs API
export const handleApiError = (error: any): string => {
  if (error.response) {
    // Le serveur a répondu avec un code d'erreur
    return error.response.data.message || 'Une erreur est survenue';
  } else if (error.request) {
    // La requête a été faite mais aucune réponse n'a été reçue
    return 'Aucune réponse du serveur';
  } else {
    // Une erreur s'est produite lors de la configuration de la requête
    return error.message || 'Une erreur est survenue';
  }
};

export default api;
