import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

interface CustomButtonProps extends Omit<MuiButtonProps, 'variant'> {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'default';
}

const Button: React.FC<CustomButtonProps> = ({ 
  variant = 'default', 
  children, 
  ...props 
}) => {
  // Mapping des variantes personnalisées vers les styles Material-UI
  const getButtonStyles = () => {
    switch (variant) {
      case 'primary':
        return {
          backgroundColor: '#3498db',
          color: 'white',
          '&:hover': {
            backgroundColor: '#2980b9',
          },
        };
      case 'secondary':
        return {
          backgroundColor: '#95a5a6',
          color: 'white',
          '&:hover': {
            backgroundColor: '#7f8c8d',
          },
        };
      case 'success':
        return {
          backgroundColor: '#2ecc71',
          color: 'white',
          '&:hover': {
            backgroundColor: '#27ae60',
          },
        };
      case 'danger':
        return {
          backgroundColor: '#e74c3c',
          color: 'white',
          '&:hover': {
            backgroundColor: '#c0392b',
          },
        };
      default:
        return {};
    }
  };

  return (
    <MuiButton
      {...props}
      variant="contained"
      sx={{
        ...getButtonStyles(),
        ...props.sx,
      }}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
