import React, { useState, useEffect } from 'react';
import { Container, Typography, Breadcrumbs, Link, Box, CircularProgress, Alert } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import EmailTemplateForm from '../../components/EmailTemplateForm';
import { emailTemplatesService, handleApiError } from '../../services';
import { EmailTemplate } from '../../types/shared';

const EditEmailTemplate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [template, setTemplate] = useState<EmailTemplate | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (!id) return;

      setLoading(true);
      try {
        const data = await emailTemplatesService.getById(id);
        setTemplate(data);
      } catch (error) {
        setError(handleApiError(error));
      } finally {
        setLoading(false);
      }
    };

    fetchTemplate();
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  if (!template) {
    return (
      <Container maxWidth="lg">
        <Alert severity="warning" sx={{ mt: 4 }}>
          Modèle d'email non trouvé
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 3, mb: 4 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/" color="inherit">
            Tableau de bord
          </Link>
          <Link component={RouterLink} to="/email-templates" color="inherit">
            Modèles d'emails
          </Link>
          <Typography color="text.primary">Modifier {template.name}</Typography>
        </Breadcrumbs>
      </Box>

      <Typography variant="h4" component="h1" gutterBottom>
        Modifier le modèle d'email
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Utilisez l'éditeur ci-dessous pour modifier le modèle d'email. Vous pouvez utiliser des variables pour personnaliser le contenu.
      </Typography>

      <EmailTemplateForm templateId={id} />
    </Container>
  );
};

export default EditEmailTemplate;
