import api from './api';
import { 
  EmailTemplate, 
  CreateEmailTemplateDto, 
  UpdateEmailTemplateDto, 
  PreviewEmailTemplateDto,
  AddAttachmentDto,
  PreviewResponseDto
} from '../types/shared';

// Service pour les modèles d'emails
const emailTemplatesService = {
  // Récupérer tous les modèles d'emails
  getAll: async (): Promise<EmailTemplate[]> => {
    const response = await api.get('/email-templates');
    return response.data;
  },

  // Récupérer un modèle d'email par ID
  getById: async (id: string): Promise<EmailTemplate> => {
    const response = await api.get(`/email-templates/${id}`);
    return response.data;
  },

  // Créer un nouveau modèle d'email
  create: async (data: CreateEmailTemplateDto): Promise<EmailTemplate> => {
    const response = await api.post('/email-templates', data);
    return response.data;
  },

  // Mettre à jour un modèle d'email
  update: async (id: string, data: UpdateEmailTemplateDto): Promise<EmailTemplate> => {
    const response = await api.patch(`/email-templates/${id}`, data);
    return response.data;
  },

  // Supprimer un modèle d'email
  delete: async (id: string): Promise<void> => {
    await api.delete(`/email-templates/${id}`);
  },

  // Prévisualiser un modèle d'email
  preview: async (data: PreviewEmailTemplateDto): Promise<PreviewResponseDto> => {
    const response = await api.post('/email-templates/preview', data);
    return response.data;
  },

  // Ajouter une pièce jointe à un modèle d'email
  addAttachment: async (id: string, data: AddAttachmentDto): Promise<EmailTemplate> => {
    const response = await api.post(`/email-templates/${id}/attachments`, data);
    return response.data;
  },

  // Supprimer une pièce jointe d'un modèle d'email
  removeAttachment: async (id: string, attachmentId: string): Promise<EmailTemplate> => {
    const response = await api.delete(`/email-templates/${id}/attachments/${attachmentId}`);
    return response.data;
  },

  // Récupérer les variables disponibles pour les modèles d'emails
  getAvailableVariables: async (): Promise<string[]> => {
    const response = await api.get('/email-templates/variables');
    return response.data;
  }
};

export default emailTemplatesService;
