import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../services/api';

// Types
export interface File {
  id: string;
  name: string;
  sourceId: string;
  sourceName: string;
  size: number;
  type: string;
  status: 'pending' | 'processed' | 'error';
  processedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

interface FilesState {
  items: File[];
  loading: boolean;
  error: string | null;
}

const initialState: FilesState = {
  items: [],
  loading: false,
  error: null,
};

// Async thunks
export const fetchFiles = createAsyncThunk(
  'files/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/files');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors du chargement des fichiers');
    }
  }
);

export const deleteFile = createAsyncThunk(
  'files/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await api.delete(`/files/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la suppression du fichier');
    }
  }
);

export const processFile = createAsyncThunk(
  'files/process',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.post(`/files/${id}/process`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors du traitement du fichier');
    }
  }
);

// Slice
const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchFiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFiles.fulfilled, (state, action: PayloadAction<File[]>) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Delete
      .addCase(deleteFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFile.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.items = state.items.filter(item => item.id !== action.payload);
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Process
      .addCase(processFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(processFile.fulfilled, (state, action: PayloadAction<File>) => {
        state.loading = false;
        const index = state.items.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(processFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearError } = filesSlice.actions;
export default filesSlice.reducer;
