import React from 'react';
import { 
  Drawer, 
  List, 
  ListItemButton,
  ListItemIcon, 
  ListItemText,
  Divider
} from '@mui/material';
import { 
  Dashboard as DashboardIcon,
  Email as EmailIcon,
  Description as FileIcon,
  Settings as SettingsIcon,
  Storage as DataSourceIcon,
  Campaign as CampaignIcon,
  Assessment as ReportIcon,
  Code as ProcessorIcon
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

// Définition des éléments du menu
const menuItems = [
  { text: 'Tableau de bord', icon: <DashboardIcon />, path: '/' },
  { text: 'Sources de données', icon: <DataSourceIcon />, path: '/data-sources' },
  { text: 'Campagnes', icon: <CampaignIcon />, path: '/campaigns' },
  { text: 'Modèles d\'emails', icon: <EmailIcon />, path: '/email-templates' },
  { text: 'Paramètres', icon: <SettingsIcon />, path: '/settings' }
];

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const drawerWidth = 200;

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItemButton
            key={item.text}
            onClick={() => navigate(item.path)}
            selected={location.pathname === item.path}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
