import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import dataSourcesService, { DataSource, CreateDataSourceDto, UpdateDataSourceDto } from '../../services/dataSourcesService';

interface DataSourcesState {
  items: DataSource[];
  loading: boolean;
  error: string | null;
}

const initialState: DataSourcesState = {
  items: [],
  loading: false,
  error: null,
};

export const fetchDataSources = createAsyncThunk(
  'dataSources/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await dataSourcesService.getAll();
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors du chargement des sources de données');
    }
  }
);

export const createDataSource = createAsyncThunk(
  'dataSources/create',
  async (data: CreateDataSourceDto, { rejectWithValue }) => {
    try {
      return await dataSourcesService.create(data);
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la création de la source de données');
    }
  }
);

export const updateDataSource = createAsyncThunk(
  'dataSources/update',
  async ({ id, data }: { id: string; data: UpdateDataSourceDto }, { rejectWithValue }) => {
    try {
      return await dataSourcesService.update(id, data);
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la mise à jour de la source de données');
    }
  }
);

export const deleteDataSource = createAsyncThunk(
  'dataSources/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await dataSourcesService.delete(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la suppression de la source de données');
    }
  }
);

const dataSourcesSlice = createSlice({
  name: 'dataSources',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchDataSources.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataSources.fulfilled, (state, action: PayloadAction<DataSource[]>) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchDataSources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Create
      .addCase(createDataSource.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDataSource.fulfilled, (state, action: PayloadAction<DataSource>) => {
        state.loading = false;
        state.items.push(action.payload);
      })
      .addCase(createDataSource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Update
      .addCase(updateDataSource.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDataSource.fulfilled, (state, action: PayloadAction<DataSource>) => {
        state.loading = false;
        const index = state.items.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateDataSource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Delete
      .addCase(deleteDataSource.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDataSource.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.items = state.items.filter(item => item.id !== action.payload);
      })
      .addCase(deleteDataSource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearError } = dataSourcesSlice.actions;
export default dataSourcesSlice.reducer;
