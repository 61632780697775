import React, { ReactNode } from 'react';
import { 
  Card as MuiCard, 
  CardContent, 
  CardHeader, 
  CardActions
} from '@mui/material';

interface CardProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  actions?: ReactNode;
  elevation?: number;
}

const Card: React.FC<CardProps> = ({ 
  title, 
  subtitle, 
  children, 
  actions,
  elevation = 1
}) => {
  return (
    <MuiCard elevation={elevation} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {title && (
        <CardHeader
          title={title}
          subheader={subtitle}
          titleTypographyProps={{ variant: 'h6' }}
          subheaderTypographyProps={{ variant: 'body2' }}
        />
      )}
      <CardContent sx={{ flexGrow: 1 }}>
        {children}
      </CardContent>
      {actions && (
        <CardActions>
          {actions}
        </CardActions>
      )}
    </MuiCard>
  );
};

export default Card;
