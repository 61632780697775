import api from './api';

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
  role: string;
}

const authService = {
  login: async (credentials: LoginCredentials): Promise<{ token: string; user: User }> => {
    const response = await api.post('/auth/login', credentials);
    const { accessToken, ...userData } = response.data;

    // Adapter les données de l'utilisateur au format attendu par l'application
    const user: User = {
      id: userData.userId,
      email: userData.email,
      name: `${userData.firstName} ${userData.lastName}`,
      role: userData.role
    };

    // Stocker le token dans le localStorage
    localStorage.setItem('token', accessToken);
    localStorage.setItem('user', JSON.stringify(user));

    return { token: accessToken, user };
  },

  logout: (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/login';
  },
  
  getCurrentUser: (): User | null => {
    try {
      const userStr = localStorage.getItem('user');
      if (!userStr) return null;
      return JSON.parse(userStr);
    } catch (error) {
      console.error('Error parsing user from localStorage:', error);
      // En cas d'erreur, supprimer les données corrompues
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      return null;
    }
  },
  
  isAuthenticated: (): boolean => {
    return localStorage.getItem('token') !== null;
  }
};

export default authService;
