import React, { useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Box, Button, Paper, Typography, TextField, Chip, Stack, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { EmailTemplate, PreviewEmailTemplateDto } from '../../types/shared';
import { emailTemplatesService } from '../../services';

interface EmailTemplateEditorProps {
  template: Partial<EmailTemplate>;
  onChange: (field: string, value: any) => void;
  variables?: string[];
}

const EmailTemplateEditor: React.FC<EmailTemplateEditorProps> = ({ template, onChange, variables = [] }) => {
  const editorRef = useRef<any>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewHtml, setPreviewHtml] = useState('');
  const [previewSubject, setPreviewSubject] = useState('');
  const [variableValues, setVariableValues] = useState<Record<string, string>>({});

  const handleEditorChange = (content: string) => {
    onChange('body', content);
  };

  const insertVariable = (variable: string) => {
    if (editorRef.current) {
      editorRef.current.insertContent(`{{ ${variable} }}`);
    }
  };

  const handlePreview = async () => {
    try {
      // Préparer les données pour la prévisualisation
      const previewData: PreviewEmailTemplateDto = {
        subject: template.subject || '',
        body: template.body || '',
        variables: variableValues
      };

      // Appeler le service de prévisualisation
      const response = await emailTemplatesService.preview(previewData);
      
      // Mettre à jour l'état avec les données de prévisualisation
      setPreviewSubject(response.subject);
      setPreviewHtml(response.body);
      setPreviewOpen(true);
    } catch (error) {
      console.error('Erreur lors de la prévisualisation du modèle:', error);
      // Gérer l'erreur (afficher un message, etc.)
    }
  };

  const handleVariableValueChange = (variable: string, value: string) => {
    setVariableValues(prev => ({
      ...prev,
      [variable]: value
    }));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Sujet de l'email
        </Typography>
        <TextField
          fullWidth
          value={template.subject || ''}
          onChange={(e) => onChange('subject', e.target.value)}
          placeholder="Saisissez le sujet de l'email"
          margin="normal"
        />
      </Paper>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Corps de l'email
        </Typography>
        <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          value={template.body || ''}
          onEditorChange={handleEditorChange}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </Paper>

      {variables.length > 0 && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Variables disponibles
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
            {variables.map((variable) => (
              <Chip
                key={variable}
                label={variable}
                onClick={() => insertVariable(variable)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </Paper>
      )}

      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Prévisualisation
        </Typography>
        <Typography variant="body2" gutterBottom>
          Pour prévisualiser le modèle avec des valeurs de test, saisissez des valeurs pour les variables ci-dessous.
        </Typography>
        
        {variables.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Valeurs des variables pour la prévisualisation
            </Typography>
            <Stack spacing={2}>
              {variables.map((variable) => (
                <TextField
                  key={variable}
                  label={variable}
                  value={variableValues[variable] || ''}
                  onChange={(e) => handleVariableValueChange(variable, e.target.value)}
                  size="small"
                />
              ))}
            </Stack>
          </Box>
        )}
        
        <Button
          variant="contained"
          onClick={handlePreview}
          sx={{ mt: 2 }}
        >
          Prévisualiser
        </Button>
      </Paper>

      {/* Dialog de prévisualisation */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Prévisualisation du modèle d'email</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Sujet: {previewSubject}
          </Typography>
          <Paper sx={{ p: 2, mt: 2 }}>
            <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmailTemplateEditor;
