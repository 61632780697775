import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Paper, Typography, Grid, CircularProgress, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EmailTemplate, CreateEmailTemplateDto, UpdateEmailTemplateDto } from '../../types/shared';
import { emailTemplatesService, handleApiError } from '../../services';
import EmailTemplateEditor from '../EmailTemplateEditor';

interface EmailTemplateFormProps {
  templateId?: string;
  onSuccess?: (template: EmailTemplate) => void;
}

const EmailTemplateForm: React.FC<EmailTemplateFormProps> = ({ templateId, onSuccess }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [template, setTemplate] = useState<Partial<EmailTemplate>>({
    name: '',
    description: '',
    subject: '',
    body: '',
    variables: []
  });
  const [availableVariables, setAvailableVariables] = useState<string[]>([]);

  const isEditMode = !!templateId;

  useEffect(() => {
    const fetchAvailableVariables = async () => {
      try {
        const variables = await emailTemplatesService.getAvailableVariables();
        setAvailableVariables(variables);
      } catch (error) {
        console.error('Erreur lors de la récupération des variables:', error);
      }
    };

    const fetchTemplate = async () => {
      if (!templateId) return;

      setLoading(true);
      try {
        const data = await emailTemplatesService.getById(templateId);
        setTemplate(data);
      } catch (error) {
        setError(handleApiError(error));
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableVariables();
    fetchTemplate();
  }, [templateId]);

  const handleChange = (field: string, value: any) => {
    setTemplate(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      let result: EmailTemplate;

      if (isEditMode) {
        const updateData: UpdateEmailTemplateDto = {
          name: template.name,
          description: template.description,
          subject: template.subject,
          body: template.body,
          variables: template.variables
        };
        result = await emailTemplatesService.update(templateId, updateData);
        setSuccess('Modèle d\'email mis à jour avec succès');
      } else {
        const createData: CreateEmailTemplateDto = {
          name: template.name || '',
          description: template.description,
          subject: template.subject || '',
          body: template.body || '',
          variables: template.variables
        };
        result = await emailTemplatesService.create(createData);
        setSuccess('Modèle d\'email créé avec succès');
      }

      if (onSuccess) {
        onSuccess(result);
      }

      // Rediriger après un court délai pour que l'utilisateur puisse voir le message de succès
      setTimeout(() => {
        navigate('/email-templates');
      }, 1500);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading && isEditMode) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Informations générales
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nom du modèle"
              value={template.name || ''}
              onChange={(e) => handleChange('name', e.target.value)}
              required
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              value={template.description || ''}
              onChange={(e) => handleChange('description', e.target.value)}
              multiline
              rows={2}
              margin="normal"
            />
          </Grid>
        </Grid>
      </Paper>

      <EmailTemplateEditor
        template={template}
        onChange={handleChange}
        variables={availableVariables}
      />

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => navigate('/email-templates')}
          sx={{ mr: 1 }}
        >
          Annuler
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : isEditMode ? 'Mettre à jour' : 'Créer'}
        </Button>
      </Box>
    </Box>
  );
};

export default EmailTemplateForm;
