import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  MenuItem, 
  FormControl, 
  FormControlLabel, 
  Switch, 
  Grid, 
  Typography,
  Divider,
  Alert
} from '@mui/material';
import { Button } from '../common';
import { DataSource, CreateDataSourceDto, UpdateDataSourceDto } from '../../services/dataSourcesService';

interface DataSourceFormProps {
  initialData?: DataSource;
  onSubmit: (data: CreateDataSourceDto | UpdateDataSourceDto) => void;
  onCancel: () => void;
  loading: boolean;
  error: string | null;
}

const sourceTypes = [
  { value: 'sftp', label: 'SFTP' },
  { value: 'api', label: 'API REST' },
  { value: 'azure', label: 'Stockage Azure' },
  { value: 'aws', label: 'AWS S3' },
  { value: 'database', label: 'Base de données' },
];

const syncSchedules = [
  { value: 'hourly', label: 'Toutes les heures' },
  { value: 'daily', label: 'Quotidien' },
  { value: 'weekly', label: 'Hebdomadaire' },
  { value: 'monthly', label: 'Mensuel' },
  { value: 'manual', label: 'Manuel uniquement' },
];

const DataSourceForm: React.FC<DataSourceFormProps> = ({ 
  initialData, 
  onSubmit, 
  onCancel, 
  loading, 
  error 
}) => {
  const [formData, setFormData] = useState<CreateDataSourceDto | UpdateDataSourceDto>({
    name: '',
    description: '',
    type: 'sftp',
    config: {},
    syncSchedule: 'daily',
  });
  
  const [isActive, setIsActive] = useState(true);
  const [configFields, setConfigFields] = useState<Record<string, string>>({});
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name,
        description: initialData.description,
        type: initialData.type,
        config: initialData.config,
        syncSchedule: initialData.syncSchedule,
      });
      setIsActive(initialData.isActive);
      
      // Convert config object to string fields for the form
      const fields: Record<string, string> = {};
      Object.entries(initialData.config).forEach(([key, value]) => {
        fields[key] = typeof value === 'object' ? JSON.stringify(value) : String(value);
      });
      setConfigFields(fields);
    } else {
      // Set default config fields based on selected type
      updateConfigFieldsForType('sftp');
    }
  }, [initialData]);

  const updateConfigFieldsForType = (type: string) => {
    let fields: Record<string, string> = {};
    
    switch (type) {
      case 'sftp':
        fields = {
          host: '',
          port: '22',
          username: '',
          password: '',
          directory: '/'
        };
        break;
      case 'api':
        fields = {
          url: '',
          method: 'GET',
          headers: '{}'
        };
        break;
      case 'azure':
        fields = {
          connectionString: '',
          container: '',
          prefix: ''
        };
        break;
      case 'aws':
        fields = {
          accessKey: '',
          secretKey: '',
          region: 'us-east-1',
          bucket: '',
          prefix: ''
        };
        break;
      case 'database':
        fields = {
          connectionString: '',
          query: 'SELECT * FROM table'
        };
        break;
      default:
        fields = {};
    }
    
    setConfigFields(fields);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Clear validation error when field is edited
    if (validationErrors[name]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Update config fields when type changes
    if (name === 'type') {
      updateConfigFieldsForType(value);
    }
  };

  const handleConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    setConfigFields(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validate = (): boolean => {
    const errors: Record<string, string> = {};
    
    if (!formData.name) {
      errors.name = 'Le nom est requis';
    }
    
    if (!formData.type) {
      errors.type = 'Le type est requis';
    }
    
    if (!formData.syncSchedule) {
      errors.syncSchedule = 'La planification est requise';
    }
    
    // Validate config fields based on type
    switch (formData.type) {
      case 'sftp':
        if (!configFields.host) errors['config.host'] = 'L\'hôte est requis';
        if (!configFields.username) errors['config.username'] = 'Le nom d\'utilisateur est requis';
        break;
      case 'api':
        if (!configFields.url) errors['config.url'] = 'L\'URL est requise';
        break;
      case 'azure':
      case 'aws':
        if (!configFields.container && !configFields.bucket) {
          errors['config.container'] = 'Le conteneur/bucket est requis';
        }
        break;
      case 'database':
        if (!configFields.connectionString) {
          errors['config.connectionString'] = 'La chaîne de connexion est requise';
        }
        break;
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validate()) {
      return;
    }
    
    // Convert config fields to object
    const config: Record<string, any> = {};
    Object.entries(configFields).forEach(([key, value]) => {
      // Try to parse JSON if it looks like JSON
      if (value.trim().startsWith('{') && value.trim().endsWith('}')) {
        try {
          config[key] = JSON.parse(value);
        } catch {
          config[key] = value;
        }
      } else if (value.trim() === 'true' || value.trim() === 'false') {
        config[key] = value.trim() === 'true';
      } else if (!isNaN(Number(value)) && value.trim() !== '') {
        config[key] = Number(value);
      } else {
        config[key] = value;
      }
    });
    
    const submitData = {
      ...formData,
      config,
    };
    
    // Add isActive only for updates
    if (initialData) {
      (submitData as UpdateDataSourceDto).isActive = isActive;
    }
    
    onSubmit(submitData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Informations générales</Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextField
            name="name"
            label="Nom"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextField
            name="type"
            label="Type"
            value={formData.type}
            onChange={handleChange}
            select
            fullWidth
            required
            error={!!validationErrors.type}
            helperText={validationErrors.type}
          >
            {sourceTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={2}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextField
            name="syncSchedule"
            label="Planification de synchronisation"
            value={formData.syncSchedule}
            onChange={handleChange}
            select
            fullWidth
            required
            error={!!validationErrors.syncSchedule}
            helperText={validationErrors.syncSchedule}
          >
            {syncSchedules.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        
        {initialData && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                }
                label="Source active"
              />
            </FormControl>
          </Grid>
        )}
        
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Configuration</Typography>
        </Grid>
        
        {Object.entries(configFields).map(([key, value]) => (
          <Grid item xs={12} md={6} key={key}>
            <TextField
              name={key}
              label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
              value={value}
              onChange={handleConfigChange}
              fullWidth
              type={key.includes('password') || key.includes('secret') ? 'password' : 'text'}
              error={!!validationErrors[`config.${key}`]}
              helperText={validationErrors[`config.${key}`]}
            />
          </Grid>
        ))}
      </Grid>
      
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button variant="secondary" onClick={onCancel} disabled={loading}>
          Annuler
        </Button>
        <Button type="submit" variant="primary" disabled={loading}>
          {loading ? 'Enregistrement...' : initialData ? 'Mettre à jour' : 'Créer'}
        </Button>
      </Box>
    </Box>
  );
};

export default DataSourceForm;
