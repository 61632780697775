import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { CampaignForm } from '../forms';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { createCampaign, updateCampaign, Campaign } from '../../store/slices/campaignsSlice';

interface CampaignModalProps {
  open: boolean;
  onClose: () => void;
  campaign?: Campaign;
}

const CampaignModal: React.FC<CampaignModalProps> = ({ open, onClose, campaign }) => {
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector(state => state.campaigns);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (data: any) => {
    setSubmitting(true);
    try {
      if (campaign) {
        await dispatch(updateCampaign({ id: campaign.id, data })).unwrap();
      } else {
        await dispatch(createCampaign(data)).unwrap();
      }
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      aria-labelledby="campaign-dialog-title"
    >
      <DialogTitle id="campaign-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">
          {campaign ? 'Modifier la campagne' : 'Créer une campagne'}
        </Typography>
        <IconButton 
          edge="end" 
          color="inherit" 
          onClick={onClose} 
          aria-label="close"
          disabled={submitting}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <CampaignForm 
          initialData={campaign}
          onSubmit={handleSubmit}
          onCancel={onClose}
          loading={loading || submitting}
          error={error}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CampaignModal;
