import React from 'react';
import { Container, Typography, Breadcrumbs, Link, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EmailTemplateForm from '../../components/EmailTemplateForm';

const CreateEmailTemplate: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 3, mb: 4 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/" color="inherit">
            Tableau de bord
          </Link>
          <Link component={RouterLink} to="/email-templates" color="inherit">
            Modèles d'emails
          </Link>
          <Typography color="text.primary">Créer un modèle</Typography>
        </Breadcrumbs>
      </Box>

      <Typography variant="h4" component="h1" gutterBottom>
        Créer un nouveau modèle d'email
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Utilisez l'éditeur ci-dessous pour créer un nouveau modèle d'email. Vous pouvez utiliser des variables pour personnaliser le contenu.
      </Typography>

      <EmailTemplateForm />
    </Container>
  );
};

export default CreateEmailTemplate;
