import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { Layout } from '../../components/layout';
import { Button, Card, Table } from '../../components/common';
import { DataSourceModal } from '../../components/modals';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchDataSources } from '../../store/slices/dataSourcesSlice';
import { DataSource } from '../../services/dataSourcesService';

// Colonnes pour le tableau
const columns = [
  { id: 'name', label: 'Nom', minWidth: 170 },
  { id: 'type', label: 'Type', minWidth: 100 },
  { id: 'description', label: 'Description', minWidth: 200 },
  {
    id: 'lastSyncDate',
    label: 'Dernière synchronisation',
    minWidth: 170,
    format: (value: string) => value ? new Date(value).toLocaleString() : 'Jamais'
  },
  { id: 'syncSchedule', label: 'Planification', minWidth: 120 }
];

const DataSourcesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { items: dataSources, loading, error } = useAppSelector(state => state.dataSources);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState<DataSource | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchDataSources());
  }, [dispatch]);

  const handleAddSource = () => {
    setSelectedDataSource(undefined);
    setModalOpen(true);
  };

  const handleEditSource = (dataSource: DataSource) => {
    setSelectedDataSource(dataSource);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h1">
            Sources de données
          </Typography>
          <Button
            variant="primary"
            startIcon={<AddIcon />}
            onClick={handleAddSource}
          >
            Ajouter une source
          </Button>
        </Box>

        <Card>
          <Table
            columns={columns}
            data={dataSources}
            loading={loading}
            error={error || undefined}
          />
          
          {!loading && dataSources.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Actions disponibles :
              </Typography>
              {dataSources.map((dataSource) => (
                <Box key={dataSource.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 200 }}>
                    {dataSource.name}
                  </Typography>
                  <Button 
                    variant="primary" 
                    size="small" 
                    onClick={() => handleEditSource(dataSource)}
                    startIcon={<EditIcon />}
                  >
                    Modifier
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </Card>
      </Box>

      <DataSourceModal
        open={modalOpen}
        onClose={handleCloseModal}
        dataSource={selectedDataSource}
      />
    </Layout>
  );
};

export default DataSourcesPage;
