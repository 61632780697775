import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../services/api';

// Types
export interface EmailTemplate {
  id: string;
  name: string;
  description: string;
  subject: string;
  body: string;
  variables: string[];
  createdAt: string;
  updatedAt: string;
}

export interface CreateEmailTemplateDto {
  name: string;
  description?: string;
  subject: string;
  body: string;
  variables?: string[];
}

export interface UpdateEmailTemplateDto {
  name?: string;
  description?: string;
  subject?: string;
  body?: string;
  variables?: string[];
}

interface EmailTemplatesState {
  items: EmailTemplate[];
  loading: boolean;
  error: string | null;
}

const initialState: EmailTemplatesState = {
  items: [],
  loading: false,
  error: null,
};

// Async thunks
export const fetchEmailTemplates = createAsyncThunk(
  'emailTemplates/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/email-templates');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors du chargement des modèles d\'emails');
    }
  }
);

export const createEmailTemplate = createAsyncThunk(
  'emailTemplates/create',
  async (data: CreateEmailTemplateDto, { rejectWithValue }) => {
    try {
      const response = await api.post('/email-templates', data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la création du modèle d\'email');
    }
  }
);

export const updateEmailTemplate = createAsyncThunk(
  'emailTemplates/update',
  async ({ id, data }: { id: string; data: UpdateEmailTemplateDto }, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/email-templates/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la mise à jour du modèle d\'email');
    }
  }
);

export const deleteEmailTemplate = createAsyncThunk(
  'emailTemplates/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await api.delete(`/email-templates/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Erreur lors de la suppression du modèle d\'email');
    }
  }
);

// Slice
const emailTemplatesSlice = createSlice({
  name: 'emailTemplates',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchEmailTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmailTemplates.fulfilled, (state, action: PayloadAction<EmailTemplate[]>) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchEmailTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Create
      .addCase(createEmailTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEmailTemplate.fulfilled, (state, action: PayloadAction<EmailTemplate>) => {
        state.loading = false;
        state.items.push(action.payload);
      })
      .addCase(createEmailTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Update
      .addCase(updateEmailTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmailTemplate.fulfilled, (state, action: PayloadAction<EmailTemplate>) => {
        state.loading = false;
        const index = state.items.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateEmailTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Delete
      .addCase(deleteEmailTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteEmailTemplate.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.items = state.items.filter(item => item.id !== action.payload);
      })
      .addCase(deleteEmailTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearError } = emailTemplatesSlice.actions;
export default emailTemplatesSlice.reducer;
