import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { Layout } from '../../components/layout';
import { Button, Card, Table } from '../../components/common';
import { CampaignModal } from '../../components/modals';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchCampaigns, Campaign } from '../../store/slices/campaignsSlice';

const columns = [
  { id: 'name', label: 'Nom', minWidth: 170 },
  { id: 'status', label: 'Statut', minWidth: 100 },
  { id: 'sourceName', label: 'Source', minWidth: 120 },
  { id: 'templateName', label: 'Modèle', minWidth: 120 },
  { 
    id: 'successRate', 
    label: 'Taux de succès', 
    minWidth: 120,
    format: (value: number) => `${value.toFixed(1)}%`
  },
  { 
    id: 'createdAt', 
    label: 'Date de création', 
    minWidth: 170,
    format: (value: string) => new Date(value).toLocaleString()
  },
];

const CampaignsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { items: campaigns, loading, error } = useAppSelector(state => state.campaigns);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);

  const handleAddCampaign = () => {
    setSelectedCampaign(undefined);
    setModalOpen(true);
  };

  const handleEditCampaign = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h1">
            Campagnes
          </Typography>
          <Button 
            variant="primary" 
            startIcon={<AddIcon />}
            onClick={handleAddCampaign}
          >
            Nouvelle campagne
          </Button>
        </Box>

        <Card>
          <Table 
            columns={columns} 
            data={campaigns} 
            loading={loading} 
            error={error || undefined} 
          />
          
          {!loading && campaigns.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Actions disponibles :
              </Typography>
              {campaigns.map((campaign) => (
                <Box key={campaign.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 200 }}>
                    {campaign.name}
                  </Typography>
                  <Button 
                    variant="primary" 
                    size="small" 
                    onClick={() => handleEditCampaign(campaign)}
                    startIcon={<EditIcon />}
                  >
                    Modifier
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </Card>
      </Box>

      <CampaignModal
        open={modalOpen}
        onClose={handleCloseModal}
        campaign={selectedCampaign}
      />
    </Layout>
  );
};

export default CampaignsPage;
