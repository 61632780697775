import api from './api';

// Types
export interface DataSource {
  id: string;
  name: string;
  description: string;
  type: string;
  config: Record<string, any>;
  isActive: boolean;
  lastSyncDate: string;
  syncSchedule: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreateDataSourceDto {
  name: string;
  description?: string;
  type: string;
  config: Record<string, any>;
  syncSchedule: string;
}

export interface UpdateDataSourceDto {
  name?: string;
  description?: string;
  type?: string;
  config?: Record<string, any>;
  isActive?: boolean;
  syncSchedule?: string;
}

// Service pour les sources de données
const dataSourcesService = {
  // Récupérer toutes les sources de données
  getAll: async (): Promise<DataSource[]> => {
    const response = await api.get('/data-sources');
    return response.data;
  },

  // Récupérer une source de données par ID
  getById: async (id: string): Promise<DataSource> => {
    const response = await api.get(`/data-sources/${id}`);
    return response.data;
  },

  // Créer une nouvelle source de données
  create: async (data: CreateDataSourceDto): Promise<DataSource> => {
    const response = await api.post('/data-sources', data);
    return response.data;
  },

  // Mettre à jour une source de données
  update: async (id: string, data: UpdateDataSourceDto): Promise<DataSource> => {
    const response = await api.patch(`/data-sources/${id}`, data);
    return response.data;
  },

  // Supprimer une source de données
  delete: async (id: string): Promise<void> => {
    await api.delete(`/data-sources/${id}`);
  },

  // Tester une connexion à une source de données
  testConnection: async (data: CreateDataSourceDto): Promise<{ success: boolean; message: string }> => {
    const response = await api.post('/data-sources/test', data);
    return response.data;
  },

  // Synchroniser une source de données
  sync: async (id: string): Promise<{ success: boolean; message: string }> => {
    const response = await api.post(`/data-sources/${id}/sync`);
    return response.data;
  }
};

export default dataSourcesService;
